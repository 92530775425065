import React, { useState, useRef, useReducer } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/dynamiques-des-territoires/home"
import SelectTerr from "../components/dynamiques-des-territoires/select"
import AppCore from "../components/dynamiques-des-territoires/appCore"
import imgIllustration from "../images/idt.svg"

/**
 * gestion de l'état du composant
 * deux props :
 * - codzone
 * - indicateur sélectionné (0/1)
 */
function reducer(state, action) {
  switch (action.type) {
    case "setCodzone":
      return { ...state, codzone: action.codzone }

    default:
      throw new Error()
  }
}

const ComparateurIDT = () => {
  // const [codzone, setCodzone] = useState(undefined)
  const selectContainer = useRef(null)
  const headerContainer = useRef(null)
  const [state, dispatch] = useReducer(reducer, {
    codzone: undefined,
  })
  const setCodzone = codzone => dispatch({ type: "setCodzone", codzone })

  return (
    <Layout
      title="Les dynamiques des territoires"
      headerContainer={headerContainer}
      img={imgIllustration}
    >
      <SEO title="Baromètre Compas - Les dynamiques des territoires" />
      <Home
        codzone={state.codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
      />
      <SelectTerr
        codzone={state.codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
        headerContainer={headerContainer}
      />
      <AppCore
        codzone={state.codzone}
        selectContainer={selectContainer}
        headerContainer={headerContainer}
      />
    </Layout>
  )
}

export default ComparateurIDT
