import React from "react"

import { ResponsiveBarCanvas } from "@nivo/bar"

export default function Histogramme({
  data,
  keys,
  margin,
  zoneColor,
  maxValue,
  minValue,
  padding
}) {
//   console.log("data", data)
  return (
    <ResponsiveBarCanvas
      data={data}
      keys={keys}
      indexBy="indicateur"
      groupMode="grouped"
      margin={{
        top: 50,
        right: 30,
        bottom: 20,
        left: 30,
        ...margin,
      }}
      maxValue={maxValue || "auto"}
      minValue={minValue || "auto"}
      padding={padding || 0.1}
      axisTop={null}
      axisRight={null}
    //   axisLeft={null}
      colors={[zoneColor, "rgba(0,0,0,0.4)"]}
      enableGridY={false}

      enableLabel={true}
      labelSkipWidth={0}     
      labelSkipHeight={0}
      labelTextColor="rgb(0,0,0)"
    
      innerPadding={3}
    />
  )
}
