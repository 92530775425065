import React from "react"
import { Stack, Text, List, ListItem } from "@chakra-ui/core"

export default function Methodo() {
  return (
    <>
      <Stack spacing={5} fontSize="sm">
        <Text>
          L'indice de développement des territoires s'appuie sur 3 indicateurs
          distincts.
        </Text>
        <List styleType="disc">
          <ListItem>le niveau de formation,</ListItem>
          <ListItem>le niveau de revenu des habitants ,</ListItem>
          <ListItem>
            la population occupant réellement un emploi âgée de 25 à 54 ans.
          </ListItem>
        </List>
        <Text>
          Une valeur indiquant l'indice de développement est calculé en tenant
          compte des valeurs des 3 indicateurs sur le département et du 
          niveau métropolitain. On attribue une note entre 1 et 5 pour chaque
          composante :{" "}
          <Text as="strong">
            plus cet indice est fort et plus le territoire est en fort
            développement
          </Text>{" "}
          au regard de la France métropolitaine. A l'inverse, un indice faible
          indique un développement plus faible. La valeur de référence pour la
          France métropolitaine vaut 3.
        </Text>
      </Stack>
    </>
  )
}
