import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Stack,
} from "@chakra-ui/core"

export default () => (
  <>
    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            Le niveau de formation
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Stack spacing={0}>
            <Text>
              Il s’agit ici d’approcher l’accès à la formation , la potentialité
              des habitants à accéder à l’emploi , la participation à la vie
              citoyenne.
            </Text>
            <Text>
              Nous avons retenu la part des hauts niveaux de formation (Bac+2 et
              plus) parmi la population ayant achevé ses études. Cet indicateur
              permet d’estimer le capital social d’un territoire.
            </Text>
          </Stack>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            Le niveau de revenu des habitants
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Text>
            Nous avons retenu le revenu médian par unité de consommation qui
            nous permet d’approcher le niveau de vie des habitants du
            territoire.
          </Text>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            La participation à la vie économique
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Text>
            Nous avons retenu une tranche d’âge se limitant à la période située
            entre 25 et 54 ans qui est la tranche d’âge où la très grande partie
            des actifs va être en situation de production.
          </Text>
          <Text>
            L’indicateur permet de définir la part de la population active
            occupée (c’est-à-dire la population occupant réellement un emploi)
            âgée de 25 à 54 ans.
          </Text>
          <Text>
            {" "}
            Ainsi, plus l’indicateur est important et plus la population est
            impliquée dans la vie active et inversement, plus l’indicateur est
            faible et moins il y a d’actifs occupés.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </>
)
