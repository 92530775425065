import React, { useState, useEffect } from "react"

import { Flex, Text, Select, Link } from "@chakra-ui/core"
import { Box, Image } from "@chakra-ui/core"
import _ from "lodash"

import ModalCriteres from "./ModalCriteres"
import ModalMethodo from "./ModalMethodo"
import Carte from "./carte"
import Histogramme from "./histogramme"
import territoires from "./dataset/territoires.json"
import Position from "./position"

import dataIndicateur from "./dataset/data-idt.json"
import CriteresIndicateur from "./Criteres"
import MethodoIndicateur from "./Methodo"

import imgNote1 from "../../images/nombres-01.svg"
import imgNote2 from "../../images/nombres-02.svg"
import imgNote3 from "../../images/nombres-03.svg"
import imgNote4 from "../../images/nombres-04.svg"
import imgNote5 from "../../images/nombres-05.svg"

function AppCore({ codzone: currentCodzone, indicateur, selectContainer }) {
  return (
    <>
      <Box ml={10} mr={10} ref={selectContainer}>
        {currentCodzone ? <Indicateur1 codzone={currentCodzone} /> : null}
      </Box>
    </>
  )
}

/**
 * preparer le dataSet pour l'indicateur Rupture
 * @param {*} currentCodzone
 */
export function preparerDatasetRupture(currentCodzone) {
  const palettes = [
    // "rgb(4,102,134)",
    // "rgb(0,156,180)",
    // "rgb(243,219,173)",
    // "rgb(225,164,51)",

    "rgb(4,102,134)",
    "rgb(0,156,180)",
    "rgb(104,195,204)",
    "rgb(243,219,173)",
    "rgb(225,164,51)",
  ]

  const intervalles = [1, 2, 3, 4]

  const labels = {
    d1: "Actifs occupés (%)",
    d2: "Hauts niveaux de formation (%)",
    d3: "Revenus déclarés médians (€)",
  }

  const dataFM = {
    codzone: "France métropolitaine",
    note: 3,
    d1: 79.7,
    d2: 29.3,
    d3: 1734,
  }

  const datum = _.find(dataIndicateur, item => item.codzone === currentCodzone)
  const territoire = _.find(
    territoires,
    terr => terr.codzone === currentCodzone
  )
  let dataForBar1 = []
  let dataForBar2 = []
  let dataForBar3 = []
  let maxValueForBar = undefined
  let zoneColor = "#FF00FF"

  if (datum) {
    dataForBar1.push({
      indicateur: labels.d1,
      [territoire.nom]: datum.d1,
      [`${territoire.nom}Color`]: "rgb(10,10,10)",
      "France métropolitaine": dataFM.d1,
    })

    dataForBar2.push({
      indicateur: labels.d2,
      [territoire.nom]: datum.d2,
      "France métropolitaine": dataFM.d2,
    })
    dataForBar3.push({
      indicateur: labels.d3,
      [territoire.nom]: datum.d3,
      "France métropolitaine": dataFM.d3,
    })
    let intervalle = 0
    while (
      intervalle < intervalles.length &&
      intervalles[intervalle] < Math.floor(datum.note)
    ) {
      intervalle++
    }
    zoneColor = palettes[intervalle]
    console.log("note", datum.note, zoneColor)
  }

  return {
    territoire,
    datum,
    dataForBar1,
    dataForBar2,
    dataForBar3,
    zoneColor,
    palettes,
    intervalles,
    labels,
  }
}

function Indicateur1({ codzone: currentCodzone }) {
  const {
    territoire,
    datum,
    dataForBar1,
    dataForBar2,
    dataForBar3,
    zoneColor,
    palettes,
    intervalles,
    labels,
  } = preparerDatasetRupture(currentCodzone)

  const getImgNote = note => {
    switch (note) {
      case 1:
        return <Image src={imgNote1} width="100px" />
      case 2:
        return <Image src={imgNote2} width="100px" />
      case 4:
        return <Image src={imgNote4} width="100px" />
      case 5:
        return <Image src={imgNote5} width="100px" />
      case 3:
      default:
        return <Image src={imgNote3} width="100px" />
    }
  }

  return (
    <>
      <Flex mt={5}>
        <Box mr={5}>
          <Text fontSize="lg" fontWeight="600" mt={5}>
            Indice de développement du territoire
          </Text>

          <Text fontSize="sm" color="gray.400">
            (1=faible développement, 5=fort développement)
          </Text>
        </Box>
        {getImgNote(Math.floor(datum.note))}
      </Flex>

      <Flex direction="row" wrap="wrap">
        <Flex minWidth="600px" height="400px">
          <Flex flexBasis="60%" pr={3}>
            <Histogramme
              data={_.concat(dataForBar1, dataForBar2)}
              keys={[territoire.nom, "France métropolitaine"]}
              zoneColor={zoneColor}
              margin={{
                right: 20,
                left: 30,
              }}
              maxValue={100}
              padding={0.15}
            />
          </Flex>

          {/* <Histogramme
            data={dataForBar2}
            keys={[territoire.nom, "France métropolitaine"]}
            zoneColor={zoneColor}
            margin={{
              right: 30,
              left: 0,
            }}
            maxValue={100}
          />
 */}
          <Flex flexBasis="40%">
            <Histogramme
              data={dataForBar3}
              keys={[territoire.nom, "France métropolitaine"]}
              zoneColor={zoneColor}
              margin={{
                right: 30,
                left: 40,
                // bottom:50
              }}
              maxValue={2400}
              //  minValue={800}
              padding={0.15}

            />
          </Flex>
        </Flex>

        <Flex direction="column">
          <Text>En comparaison avec les autres départements</Text>
          <Carte
            palettes={palettes}
            intervalles={intervalles}
            data={dataIndicateur}
          />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" my={10}>
        <Flex>
          <Box mr={5}>
            <ModalMethodo>
              <MethodoIndicateur />
            </ModalMethodo>
          </Box>
          <ModalCriteres>
            <CriteresIndicateur />
          </ModalCriteres>
        </Flex>
        <Flex>
          <Text fontSize="xs">
            Sources :{" "}
            <Link href="http://www.le-compas.fr" isExternal>
              Traitements Compas©
            </Link>
            , Insee RP 2016
          </Text>
        </Flex>
        <Flex>
          <Position
            palettes={palettes}
            intervalles={intervalles}
            note={datum.note}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default AppCore
