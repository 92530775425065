import React from "react"
import {
  Flex,
  Text,
  Box,
  Stack,
  Icon,
  Link as ChakraLink,
  Image,
} from "@chakra-ui/core"

import startImg from "../../images/Sonar_logo_V4-250x131.png"
import imgLogo from "../../images/logo.png"

export default function Home() {
  return (
    <>
      <Flex alignItems="center" mr={10} ml={10}>
        <Flex direction="column" flexBasis="70%" pr={10}>
          <Stack spacing={5} mb={10}>
            <Text fontSize="lg" fontWeight="600">
              L'Indice de Développement des Territoires (IDT)
            </Text>
            <Text>
              Pour les acteurs de l’action sociale locale, la logique de
              territoires devient de plus en plus importante afin de mieux
              cibler les interventions (de les différencier) et de mieux
              localiser ses réponses. Les politiques territoriales en France
              sont aussi très nombreuses : pour les centres bourgs, pour les
              territoires ruraux, pour les quartiers de la politique de la
              ville, …
            </Text>

            <Text>
              L’Indice de Dynamique des Territoires (IDT) s’inscrit dans une
              logique de compréhension des écarts entre les espaces
              géographiques au sein du territoire national, comprendre ce qui{" "}
              <Text as="em">bouge</Text> dans un territoire et de le comparer à
              ce qui <Text as="em">bouge</Text> dans les autres territoires.
            </Text>

            <Text>
              Le{" "}
              <ChakraLink href="http://www.lecompas.fr/" isExternal>
                Compas
                <Icon name="external-link" mx="2px" />
              </ChakraLink>{" "}
              met à votre disposition cet outil au niveau de l'échelle
              départementale au regard du développement de la France
              métropolitaine (il peut cependant s'appliquer à tous vos
              territoires).
            </Text>
          </Stack>
        </Flex>
        <Flex
          flexBasis="20%"
          flexDirection="column"
          fontSize="xs"
          alignItems="center"
          textAlign="center"
        >
          <Text as="strong">Mieux nous connaître</Text>
          <Image src={imgLogo} my={1} />
          <ChakraLink
            href="http://www.lecompas.fr"
            isExternal
            backgroundColor="rgb(22,171,228)"
            color="white"
            p="1"
            my={1}
          >
            lecompas.fr
            <Icon name="external-link" mx="2px" />
          </ChakraLink>
        </Flex>
      </Flex>
    </>
  )
}
